import { createSlice, } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '../locale/i18';
import { api } from '../utils/api';
import { RestaurantType } from './restaurantSlice';


export type MemberType = {
    "id": 1,
    "users_permissions_user": {
      "id": 1,
      "username": "juanjoslopezv",
      "email": "juanjoslopezv@gmail.com",
      "provider": "local",
      "confirmed": true,
      "blocked": false,
      "role": 1,
      "created_at": "2021-02-01T05:57:03.420Z",
      "updated_at": "2021-02-06T07:55:10.328Z",
      "phone": "+523121558080",
      "avatar": {
        "id": 26,
        "name": "IMG_6034.jpeg",
        "alternativeText": "",
        "caption": "",
        "width": 1412,
        "height": 1078,
        "formats": {
          "large": {
            "ext": ".jpeg",
            "url": "/uploads/large_IMG_6034_bb4a2bd93d.jpeg",
            "hash": "large_IMG_6034_bb4a2bd93d",
            "mime": "image/jpeg",
            "name": "large_IMG_6034.jpeg",
            "path": null,
            "size": 185.2,
            "width": 1000,
            "height": 763
          },
          "small": {
            "ext": ".jpeg",
            "url": "/uploads/small_IMG_6034_bb4a2bd93d.jpeg",
            "hash": "small_IMG_6034_bb4a2bd93d",
            "mime": "image/jpeg",
            "name": "small_IMG_6034.jpeg",
            "path": null,
            "size": 54.4,
            "width": 500,
            "height": 382
          },
          "medium": {
            "ext": ".jpeg",
            "url": "/uploads/medium_IMG_6034_bb4a2bd93d.jpeg",
            "hash": "medium_IMG_6034_bb4a2bd93d",
            "mime": "image/jpeg",
            "name": "medium_IMG_6034.jpeg",
            "path": null,
            "size": 115.44,
            "width": 750,
            "height": 573
          },
          "thumbnail": {
            "ext": ".jpeg",
            "url": "/uploads/thumbnail_IMG_6034_bb4a2bd93d.jpeg",
            "hash": "thumbnail_IMG_6034_bb4a2bd93d",
            "mime": "image/jpeg",
            "name": "thumbnail_IMG_6034.jpeg",
            "path": null,
            "size": 9.19,
            "width": 204,
            "height": 156
          }
        },
        "hash": "IMG_6034_bb4a2bd93d",
        "ext": ".jpeg",
        "mime": "image/jpeg",
        "size": 322.3,
        "url": "/uploads/IMG_6034_bb4a2bd93d.jpeg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2021-02-06T07:54:55.902Z",
        "updated_at": "2021-02-06T07:54:55.944Z"
      }
    },
    "balance": 100,
    "created_at": "2021-02-06T07:48:39.390Z",
    "updated_at": "2021-02-06T08:29:38.663Z",
    "restaurant": {
      "id": 72,
      "name": "Restaurant Name",
      "description": null,
      "LocationID": "1e812bcf-660c-4a64-8690-a3e802cc8f97",
      "created_at": "2020-12-22T06:04:06.620Z",
      "updated_at": "2021-01-11T03:00:49.418Z",
      "published_at": "2020-12-22T06:04:06.045Z",
      "address": "test address",
      "kosher": null,
      "facebook": null,
      "instagram": null,
      "lang": null,
      "telephone": "test employer phone",
      "color": "#0000ff",
      "colorText": "#FFFFFF",
      "rate": null,
      "location": 1,
      "averageTime": null,
      "averagePrice": null,
      "currency": "₪",
      "tax": null,
      "logo": {
        "id": 18,
        "name": "whitney-wright-6G98hiCJETA-unsplash.jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1543,
        "height": 1080,
        "formats": {
          "large": {
            "ext": ".jpg",
            "url": "/uploads/large_whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd.jpg",
            "hash": "large_whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd",
            "mime": "image/jpeg",
            "name": "large_whitney-wright-6G98hiCJETA-unsplash.jpg",
            "path": null,
            "size": 122.33,
            "width": 1000,
            "height": 700
          },
          "small": {
            "ext": ".jpg",
            "url": "/uploads/small_whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd.jpg",
            "hash": "small_whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd",
            "mime": "image/jpeg",
            "name": "small_whitney-wright-6G98hiCJETA-unsplash.jpg",
            "path": null,
            "size": 38.43,
            "width": 500,
            "height": 350
          },
          "medium": {
            "ext": ".jpg",
            "url": "/uploads/medium_whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd.jpg",
            "hash": "medium_whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd",
            "mime": "image/jpeg",
            "name": "medium_whitney-wright-6G98hiCJETA-unsplash.jpg",
            "path": null,
            "size": 73.45,
            "width": 750,
            "height": 525
          },
          "thumbnail": {
            "ext": ".jpg",
            "url": "/uploads/thumbnail_whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd.jpg",
            "hash": "thumbnail_whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd",
            "mime": "image/jpeg",
            "name": "thumbnail_whitney-wright-6G98hiCJETA-unsplash.jpg",
            "path": null,
            "size": 11.02,
            "width": 223,
            "height": 156
          }
        },
        "hash": "whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 265.1,
        "url": "/uploads/whitney_wright_6_G98hi_CJETA_unsplash_3603bc9ffd.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2021-01-01T20:41:45.013Z",
        "updated_at": "2021-01-01T20:41:45.043Z"
      },
      "photos": [
        {
          "id": 19,
          "name": "carissa-gan-_0JpjeqtSyg-unsplash.jpg",
          "alternativeText": "",
          "caption": "",
          "width": 2000,
          "height": 1334,
          "formats": {
            "large": {
              "ext": ".jpg",
              "url": "/uploads/large_carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41.jpg",
              "hash": "large_carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41",
              "mime": "image/jpeg",
              "name": "large_carissa-gan-_0JpjeqtSyg-unsplash.jpg",
              "path": null,
              "size": 102.55,
              "width": 1000,
              "height": 667
            },
            "small": {
              "ext": ".jpg",
              "url": "/uploads/small_carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41.jpg",
              "hash": "small_carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41",
              "mime": "image/jpeg",
              "name": "small_carissa-gan-_0JpjeqtSyg-unsplash.jpg",
              "path": null,
              "size": 36.82,
              "width": 500,
              "height": 334
            },
            "medium": {
              "ext": ".jpg",
              "url": "/uploads/medium_carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41.jpg",
              "hash": "medium_carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41",
              "mime": "image/jpeg",
              "name": "medium_carissa-gan-_0JpjeqtSyg-unsplash.jpg",
              "path": null,
              "size": 66.3,
              "width": 750,
              "height": 500
            },
            "thumbnail": {
              "ext": ".jpg",
              "url": "/uploads/thumbnail_carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41.jpg",
              "hash": "thumbnail_carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41",
              "mime": "image/jpeg",
              "name": "thumbnail_carissa-gan-_0JpjeqtSyg-unsplash.jpg",
              "path": null,
              "size": 11.71,
              "width": 234,
              "height": 156
            }
          },
          "hash": "carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "size": 283.91,
          "url": "/uploads/carissa_gan_0_Jpjeqt_Syg_unsplash_3a5775cf41.jpg",
          "previewUrl": null,
          "provider": "local",
          "provider_metadata": null,
          "created_at": "2021-01-05T06:55:25.681Z",
          "updated_at": "2021-01-05T06:55:25.712Z"
        }
      ],
      "featured": [
        {
          "id": 17,
          "name": "jannis-brandt-8manzosDSGM-unsplash.jpg",
          "alternativeText": "",
          "caption": "",
          "width": 1571,
          "height": 1080,
          "formats": {
            "large": {
              "ext": ".jpg",
              "url": "/uploads/large_jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca.jpg",
              "hash": "large_jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca",
              "mime": "image/jpeg",
              "name": "large_jannis-brandt-8manzosDSGM-unsplash.jpg",
              "path": null,
              "size": 166.99,
              "width": 1000,
              "height": 687
            },
            "small": {
              "ext": ".jpg",
              "url": "/uploads/small_jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca.jpg",
              "hash": "small_jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca",
              "mime": "image/jpeg",
              "name": "small_jannis-brandt-8manzosDSGM-unsplash.jpg",
              "path": null,
              "size": 44.19,
              "width": 500,
              "height": 344
            },
            "medium": {
              "ext": ".jpg",
              "url": "/uploads/medium_jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca.jpg",
              "hash": "medium_jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca",
              "mime": "image/jpeg",
              "name": "medium_jannis-brandt-8manzosDSGM-unsplash.jpg",
              "path": null,
              "size": 93.74,
              "width": 750,
              "height": 516
            },
            "thumbnail": {
              "ext": ".jpg",
              "url": "/uploads/thumbnail_jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca.jpg",
              "hash": "thumbnail_jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca",
              "mime": "image/jpeg",
              "name": "thumbnail_jannis-brandt-8manzosDSGM-unsplash.jpg",
              "path": null,
              "size": 10.78,
              "width": 227,
              "height": 156
            }
          },
          "hash": "jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca",
          "ext": ".jpg",
          "mime": "image/jpeg",
          "size": 400.42,
          "url": "/uploads/jannis_brandt_8manzos_DSGM_unsplash_3bf59195ca.jpg",
          "previewUrl": null,
          "provider": "local",
          "provider_metadata": null,
          "created_at": "2021-01-01T20:41:15.630Z",
          "updated_at": "2021-01-01T20:41:15.656Z"
        }
      ]
    }
}

export const fetchMembers = createAsyncThunk(
	'members/fetch',
	async ({id} : {id : string}) => {
		console.log("membersclubSlice fetch baseURL", api.defaults.baseURL + `/membersclubs?restaurant=${id}`);
        console.log("membersclubSlice fetch params", id);
        
		const response = await api.get(
			`/membersclubs?restaurant=${id}`
		);
		console.log('fetchMembers response', response.data);
        return response.data;
        
	}
);

export const sendSMS = createAsyncThunk(
	'members/sendSMS',
	async ({id, body} : {id : number, body : string}) => {
		console.log("membersclubSlice fetch baseURL", api.defaults.baseURL + `/membersclubs/sms`);
        console.log("membersclubSlice fetch params", id);
        
		const response = await api.post(
			`/membersclubs/sms`, {
        id,
        body
      }
		);
		console.log('sendSMS response', response.data);
        return response.data;
        
	}
);


type InitialStateType = {
    fetching : false;
    members : Array<MemberType>,
    error : string | null;
    sms : any;
    smsSending : boolean;
}

const jwtToken = localStorage.getItem("jwt")            
const userData = localStorage.getItem("user")

const initialState : InitialStateType = {
    fetching : false,
    members : [],
    error : "",
    sms : {},
    smsSending : false
}

const membersclubSlice = createSlice({
    name: 'membersclub',
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
        //Fecth device info
        builder.addCase(fetchMembers.pending, (state) => {
            
            state.error = null
        });
        builder.addCase(fetchMembers.fulfilled, (state, action) => {
            state.members = action.payload
            
        });
        builder.addCase(fetchMembers.rejected, (state, action :any) => {
            state.error = action.error.message;
            
        });
        builder.addCase(sendSMS.pending, (state) => {
            
            state.smsSending = true
        });
        builder.addCase(sendSMS.fulfilled, (state, action) => {
            state.sms = action.payload
            state.smsSending = false
            
        });
        builder.addCase(sendSMS.rejected, (state, action :any) => {
            state.error = action.error.message;
            state.smsSending = false
            
        });

    }
})


export default membersclubSlice