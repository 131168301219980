import React, {useState, useEffect} from 'react'
import i18n from '../../locale/i18'
import { api } from '../../utils/api'

const BusinessCustomers = () => {

    const [Restaurants, setRestaurants] = useState([])

   useEffect(() => {
       
    api.get("/users")
    .then(({ data }) => {
        setRestaurants(data)
    })
   }, [])

    return (
        <div className="main-content">
            <div className="container-fluid">
                <div className="header">
                    <div className="header-body">
                        <div className="row align-items-center">
                            <div className="col">


                                <h6 className="header-pretitle">
                                    Overview
                    </h6>


                                <h1 className="header-title text-truncate">
                                    {i18n.t("business-customer")}
                                </h1>

                            </div>

                        </div>

                    </div>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{i18n.t("business-money-name")}</th>
                            <th scope="col">{i18n.t("business-money-address")}</th>
                            <th scope="col">{i18n.t("business-money-phone")}</th>
                            <th scope="col">{i18n.t("business-money-contact")}</th>
                            <th scope="col">{i18n.t("business-money-comission-9")}</th>
                            <th scope="col">{i18n.t("business-money-comission-5")}</th>
                            <th scope="col">{i18n.t("business-money-comission-2")}</th>
                            <th scope="col">{i18n.t("business-money-comission-2")}</th>
                            <th scope="col">{i18n.t("business-money-sms-sent")}</th>
                            <th scope="col">{i18n.t("business-money-order-count")}</th>
                            <th scope="col">{i18n.t("business-money-order-avg")}</th>
                            <th scope="col">{i18n.t("business-money-order-delivery")}</th>
                            <th scope="col">{i18n.t("business-money-avg-reviews")}</th>
                            <th scope="col">{i18n.t("business-money-new-members")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Restaurants.map((restaurant :any, key) => {
                            return <tr key={key}>
                                <th scope="row">{key}</th>
                                <td>{restaurant.firstName + " " + restaurant.lastName}</td>
                                <td>{restaurant.address}</td>
                                <td>{restaurant.telephone}</td>
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BusinessCustomers
