export const Color = {
    green: "#459D72",
    pink: "#D00080",
    black: "#1A1A1A",
    lighBlack: "#342B2B",
    gray: "#D0D0D0",
    white: "#fff",
    darkGray: "#A0A0A0",
    lightGray: "#E7E7E7"
}

Color.green = Color.pink;