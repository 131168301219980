import React from 'react';
import logo from './logo.svg';
import './App.css';

import { Provider } from 'react-redux'
import store from './store'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';


import MainView from './views/MainView';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Route path="/" component={MainView} />
      </Router>
    </Provider>
    
  );
}

export default App;
