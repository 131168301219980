import React, { useEffect, useState } from 'react'

const InviteMemberModal = () => {
    const [Email, setEmail] = useState("")
    useEffect(() => {
        
        return () => {
            
        }
    }, [])

    const onChange = (e:any) => {
        switch(e.target.name){
            case "email":
                break;
            case "fullName":
                break;
            default:
                break;
        }
    }

    return (
        <div className="dropdown">
                  <button className="btn btn-sm btn-primary" type="button" id="inviteMember" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Invite member
                  </button>
                  <form className="dropdown-menu dropdown-menu-right dropdown-menu-card" aria-labelledby="inviteMember">
                    <div className="card-header">

                      
                      <h4 className="card-header-title">
                        Invite a member
                      </h4>

                    </div>
                    <div className="card-header">
                      <div className="row no-gutters align-items-center">
                        <div className="col-3">

                          
                          <label className="mb-0" htmlFor="inviteMemberName">
                            Name
                          </label>

                        </div>
                        <div className="col">

                          
                          <input className="form-control form-control-flush" name="fullName" onChange={onChange} id="inviteMemberName" type="text" placeholder="Full name" />

                        </div>
                      </div> 
                    </div>
                    <div className="card-header">
                      <div className="row no-gutters align-items-center">
                        <div className="col-3">

                          
                          <label className="mb-0" htmlFor="inviteMemberEmail">
                            Email
                          </label>

                        </div>
                        <div className="col">

                          
                          <input className="form-control form-control-flush" onChange={onChange} id="inviteMemberEmail" type="text" name="email" placeholder="Email address" />

                        </div>
                      </div> 
                    </div>
                    <div className="card-footer">

                      
                      <button className="btn btn-block btn-primary" type="submit">
                        Invite member
                      </button>

                    </div>
                  </form>
                </div>
    )
}

export default InviteMemberModal
