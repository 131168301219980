import { createSlice, configureStore } from '@reduxjs/toolkit'
import authSlice from './redux/authSlice'
import restaurantSlice from './redux/restaurantSlice'
import membersclubSlice from './redux/membersclubSlice'
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  restaurant : restaurantSlice.reducer,
  membersclub : membersclubSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer
})

export default store