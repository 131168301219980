import React, {useEffect, useState} from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import i18n from '../locale/i18'
import { getRestaurantDetails } from '../redux/restaurantSlice'
import { RootState } from '../store'
import { api } from '../utils/api'

const Restaurant = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    

    const restaurant = useSelector((state:RootState) => state.restaurant)
    const {restaurantDetails} = restaurant

    const {id} = useParams<{id :string}>()
    const getDetails = () => {
      //console.log("getDetails", currentPath)
        dispatch(getRestaurantDetails({id}))
    }

    useEffect(() => {
        getDetails()
        return () => {
            
        }
    }, [])
   
    return (

            <div className="main-content">

      
<div className="header">
  <div className="container-fluid">

    
    <div className="header-body">
      <div className="row align-items-center">
        <div className="col-auto">

          
          <div className="avatar avatar-lg avatar-4by3">
            <img src={restaurant.restaurantDetails?.logo ? api.getMedia!(restaurant.restaurantDetails?.logo!) : "/assets/img/avatars/projects/project-1.jpg"} alt="..." className="avatar-img rounded" />
          </div>

        </div>
        <div className="col ml-n3 ml-md-n2">

          
          <h6 className="header-pretitle">
            {i18n.t("restaurant")}
          </h6>

          
          <h1 className="header-title">
            {restaurantDetails?.name}
          </h1>

        </div>
        <div className="col-12 col-md-auto mt-3 mt-md-0">

          
          <div className="avatar-group">
            <a href="profile-posts.html" className="avatar" data-toggle="tooltip" title="Dianna Smiley">
              <img src="/assets/img/avatars/profiles/avatar-1.jpg" alt="..." className="avatar-img rounded-circle"/>
            </a>
            <a href="profile-posts.html" className="avatar" data-toggle="tooltip" title="Ab Hadley">
              <img src="/assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle"/>
            </a>
            <a href="profile-posts.html" className="avatar" data-toggle="tooltip" title="Adolfo Hess">
              <img src="/assets/img/avatars/profiles/avatar-3.jpg" alt="..." className="avatar-img rounded-circle"/>
            </a>
            <a href="profile-posts.html" className="avatar" data-toggle="tooltip" title="Daniela Dewitt">
              <img src="/assets/img/avatars/profiles/avatar-4.jpg" alt="..." className="avatar-img rounded-circle"/>
            </a>
          </div>

          
          <a href="#modalMembers" className="btn btn-lg btn-rounded-circle btn-white lift" data-toggle="modal">
            +
          </a>

        </div>
      </div> 
      <div className="row align-items-center">
        <div className="col">

          
          <ul className="nav nav-tabs nav-overflow header-tabs">
            <li className="nav-item">
              <a href="project-overview.html" className="nav-link active">
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a href="project-files.html" className="nav-link">
                Files
              </a>
            </li>
            <li className="nav-item">
              <a href="project-reports.html" className="nav-link">
                Reports
              </a>
            </li>
          </ul>

        </div>
      </div>
    </div>

  </div>
</div> 


<div className="container-fluid">
  <div className="row">
    <div className="col-12">

      
      <div className="alert alert-primary">
        This project is forecasted to complete below budget!

        
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>

      </div>

      
      <div className="card">
        <div className="card-header">

          
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item" data-toggle="chart" data-target="#overviewChart" data-trigger="click" data-action="toggle" data-dataset="0">
              <a className="nav-link active" href="#" data-toggle="tab">
                Earned
              </a>
            </li>
            <li className="nav-item" data-toggle="chart" data-target="#overviewChart" data-trigger="click" data-action="toggle" data-dataset="1">
              <a className="nav-link" href="#" data-toggle="tab">
                Hours Worked
              </a>
            </li>
          </ul>

        </div>
        <div className="card-body">

          
          <div className="chart">
            <canvas id="overviewChart" className="chart-canvas"></canvas>
          </div>

        </div>
      </div>

    </div>
  </div> 
  <div className="row">
    <div className="col-12 col-lg-6 col-xl">

      
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">

              
              <h6 className="text-uppercase text-muted mb-2">
                Budget
              </h6>

              
              <span className="h2 mb-0">
                $24,500
              </span>

              
              <span className="badge badge-soft-success mt-n1">
                +3.5%
              </span>

            </div>
            <div className="col-auto">

              
              <span className="h2 fe fe-dollar-sign text-muted mb-0"></span>

            </div>
          </div> 

        </div>
      </div>

    </div>
    <div className="col-12 col-lg-6 col-xl">

      
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">

              
              <h6 className="text-uppercase text-muted mb-2">
                Total Hours
              </h6>

              
              <span className="h2 mb-0">
                763.5
              </span>

            </div>
            <div className="col-auto">

              
              <span className="h2 fe fe-briefcase text-muted mb-0"></span>

            </div>
          </div> 

        </div>
      </div>

    </div>
    <div className="col-12 col-lg-6 col-xl">

      
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">

              
              <h6 className="text-uppercase text-muted mb-2">
                Progress
              </h6>

              <div className="row align-items-center no-gutters">
                <div className="col-auto">

                  
                  <span className="h2 mr-2 mb-0">
                    84.5%
                  </span>

                </div>
                <div className="col">

                  
                  <div className="progress progress-sm">
                    <div className="progress-bar" role="progressbar" style={{width: "85%"}} aria-valuenow={85} aria-valuemin={0} aria-valuemax={100}></div>
                  </div>

                </div>
              </div> 

            </div>
            <div className="col-auto">

              
              <span className="h2 fe fe-clipboard text-muted mb-0"></span>

            </div>
          </div> 

        </div>
      </div>

    </div>
    <div className="col-12 col-lg-6 col-xl">

      
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col">

              
              <h6 className="text-uppercase text-muted mb-2">
                Cost/Unit
              </h6>

              
              <span className="h2 mb-0">
                $5.50
              </span>

            </div>
            <div className="col-auto">

              
              <div className="chart chart-sparkline">
                <canvas className="chart-canvas" id="sparklineChart"></canvas>
              </div>

            </div>
          </div> 

        </div>
      </div>

    </div>
  </div> 
  <div className="row">
    <div className="col-12 col-xl-8">

      
      <div className="card">
        <div className="card-header">

          
          <h4 className="card-header-title">
            Performance
          </h4>


          
          <a href="#!" className="btn btn-sm btn-white">
            Export
          </a>

        </div>
        <div className="table-responsive mb-0">
          <table className="table table-sm table-nowrap card-table">
            <thead>
              <tr>
                <th>
                  Member
                </th>
                <th>
                  Schedule
                </th>
                <th>
                  Hours Billed
                </th>
                <th>
                  Completion
                </th>
                <th>
                  Effective Rate
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>

                  
                  <a href="profile-posts.htmL" className="avatar avatar-xs d-inline-block mr-2">
                    <img src="/assets/img/avatars/profiles/avatar-1.jpg" alt="..." className="avatar-img rounded-circle"/>
                  </a>

                  
                  <span>Dianna Smiley</span>

                </td>
                <td>
                  <span className="text-success">●</span> On Schedule
                </td>
                <td>
                  271
                </td>
                <td>
                  <div className="row align-items-center no-gutters">
                    <div className="col-auto">

                      
                      <span className="mr-2">55%</span>

                    </div>
                    <div className="col">

                      
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-secondary" role="progressbar" style={{width: "55%"}} aria-valuenow={55} aria-valuemin={0} aria-valuemax={100}></div>
                      </div>

                    </div>
                  </div> 
                </td>
                <td>
                  $55.25%
                </td>
              </tr>
              <tr>
                <td>

                  
                  <a href="profile-posts.html" className="avatar avatar-xs d-inline-block mr-2">
                    <img src="/assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle"/>
                  </a>

                  
                  <span>Ab Hadley</span>

                </td>
                <td>
                  <span className="text-warning">●</span> Delayed
                </td>
                <td>
                  44
                </td>
                <td>
                  <div className="row align-items-center no-gutters">
                    <div className="col-auto">

                      
                      <span className="mr-2">25%</span>

                    </div>
                    <div className="col">

                      
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-secondary" role="progressbar" style={{width: "25%"}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                      </div>

                    </div>
                  </div> 
                </td>
                <td>
                  $122.52%
                </td>
              </tr>
              <tr>
                <td>

                  
                  <a href="profile-posts.html" className="avatar avatar-xs d-inline-block mr-2">
                    <img src="/assets/img/avatars/profiles/avatar-3.jpg" alt="..." className="avatar-img rounded-circle"/>
                  </a>

                  
                  <span>Adolfo Hess</span>

                </td>
                <td>
                  <span className="text-success">●</span> On Schedule
                </td>
                <td>
                  271
                </td>
                <td>
                  <div className="row align-items-center no-gutters">
                    <div className="col-auto">

                      
                      <span className="mr-2">55%</span>

                    </div>
                    <div className="col">

                      
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-secondary" role="progressbar" style={{width: "55%"}} aria-valuenow={55} aria-valuemin={0} aria-valuemax={100}></div>
                      </div>

                    </div>
                  </div> 
                </td>
                <td>
                  $55.25%
                </td>
              </tr>
              <tr>
                <td>

                  
                  <a href="profile-posts.html" className="avatar avatar-xs d-inline-block mr-2">
                    <img src="/assets/img/avatars/profiles/avatar-4.jpg" alt="..." className="avatar-img rounded-circle"/>
                  </a>

                  
                  <span>Daniela Dewitt</span>

                </td>
                <td>
                  <span className="text-warning">●</span> Delayed
                </td>
                <td>
                  44
                </td>
                <td>
                  <div className="row align-items-center no-gutters">
                    <div className="col-auto">

                      
                      <span className="mr-2">25%</span>

                    </div>
                    <div className="col">

                      
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-secondary" role="progressbar" style={{width: "25%"}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                      </div>

                    </div>
                  </div> 
                </td>
                <td>
                  $122.52%
                </td>
              </tr>
              <tr>
                <td>

                  
                  <a href="profile-posts.html" className="avatar avatar-xs d-inline-block mr-2">
                    <img src="/assets/img/avatars/profiles/avatar-5.jpg" alt="..." className="avatar-img rounded-circle"/>
                  </a>

                  
                  <span>Miyah Myles</span>

                </td>
                <td>
                  <span className="text-success">●</span> On Schedule
                </td>
                <td>
                  271
                </td>
                <td>
                  <div className="row align-items-center no-gutters">
                    <div className="col-auto">

                      
                      <span className="mr-2">55%</span>

                    </div>
                    <div className="col">

                      
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-secondary" role="progressbar" style={{width: "55%"}} aria-valuenow={55} aria-valuemin={0} aria-valuemax={100}></div>
                      </div>

                    </div>
                  </div> 
                </td>
                <td>
                  $55.25%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
    <div className="col-12 col-xl-4">

      
      <div className="card">
        <div className="card-header">

          
          <h4 className="card-header-title">
            Latest Files
          </h4>

          
          <a href="#!" className="small">View all</a>

        </div>
        <div className="card-body">

          
          <div className="list-group list-group-flush my-n3">
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col-auto">

                  
                  <a href="#!" className="avatar">
                    <img src="/assets/img/files/file-1.jpg" alt="..." className="avatar-img rounded" />
                  </a>

                </div>
                <div className="col ml-n2">

                  
                  <h4 className="mb-1">
                    <a href="#!">Launchday Logo</a>
                  </h4>

                  
                  <p className="card-text small text-muted">
                    2.5kb SVG
                  </p>

                </div>
                <div className="col-auto">

                  
                  <div className="dropdown">
                    <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe fe-more-vertical"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a href="#!" className="dropdown-item">
                        Action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Another action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Something else here
                      </a>
                    </div>
                  </div>

                </div>
              </div> 
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col-auto">

                  
                  <a href="#!" className="avatar">
                    <img src="/assets/img/files/file-2.jpg" alt="..." className="avatar-img rounded" />
                  </a>

                </div>
                <div className="col ml-n2">

                  
                  <h4 className="mb-1">
                    <a href="#!">Example Grid</a>
                  </h4>

                  
                  <p className="card-text small text-muted">
                    1.5mb PNG
                  </p>

                </div>
                <div className="col-auto">

                  
                  <div className="dropdown">
                    <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe fe-more-vertical"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a href="#!" className="dropdown-item">
                        Action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Another action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Something else here
                      </a>
                    </div>
                  </div>

                </div>
              </div> 
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col-auto">

                  
                  <a href="#!" className="avatar">
                    <div className="avatar-title rounded bg-white text-secondary">
                      <span className="fe fe-folder"></span>
                    </div>
                  </a>

                </div>
                <div className="col ml-n2">

                  
                  <h4 className="mb-1">
                    <a href="#!">Screenshot Collection</a>
                  </h4>

                  
                  <p className="card-text small text-muted">
                    6.9mb directory
                  </p>

                </div>
                <div className="col-auto">

                  
                  <div className="dropdown">
                    <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe fe-more-vertical"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a href="#!" className="dropdown-item">
                        Action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Another action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Something else here
                      </a>
                    </div>
                  </div>

                </div>
              </div> 
            </div>
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col-auto">

                  
                  <a href="#!" className="avatar">
                    <img src="/assets/img/files/file-3.jpg" alt="..." className="avatar-img rounded" />
                  </a>

                </div>
                <div className="col ml-n2">

                  
                  <h4 className="mb-1">
                    <a href="#!">Launchday Cover</a>
                  </h4>

                  
                  <p className="card-text small text-muted">
                    750kb JPG
                  </p>

                </div>
                <div className="col-auto">

                  
                  <div className="dropdown">
                    <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe fe-more-vertical"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a href="#!" className="dropdown-item">
                        Action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Another action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Something else here
                      </a>
                    </div>
                  </div>

                </div>
              </div> 
            </div>
          </div>

        </div>
      </div>

    </div>
  </div> 
</div>

</div>
    )
}

export default Restaurant
