import { createSlice, } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '../locale/i18';
import { api } from '../utils/api';
import { RestaurantType } from './restaurantSlice';

export type LoginParamsType = {
    identifier: string;
    password: string;
}
export const login = createAsyncThunk(
	'auth/login',
	async (params: LoginParamsType) => {
		console.log("authSlice login baseURL", api.defaults.baseURL + '/auth/local');
        console.log("authSlice login params", params);
        
		const response = await api.post(
			'/auth/local',
            params
		);
		console.log('login auth response', response.data);
        return response.data;
        
	}
);
export const fetchMe = createAsyncThunk(
	'auth/me',
	async ({id}: {id : number}) => {
		console.log("authSlice me baseURL", api.defaults.baseURL + '/users/' + id);
        
		const response = await api.get(
			'/users/' + id
		);
		console.log('login auth response', response.data);
        return response.data;
        
	}
);

type InitialStateType = {
    jwt : string | null;
    authenticating : boolean;
    error : any,
    user :  {
        id : number;
        restaurants : Array<RestaurantType>
    } | null
}

const jwtToken = localStorage.getItem("jwt")            
const userData = localStorage.getItem("user")

const initialState : InitialStateType = {
    jwt: jwtToken,
    authenticating : false,
    error : undefined,
    user : userData ? JSON.parse(userData) : null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout : (state) => {
            state.jwt = null
            state.user = null
            localStorage.setItem("jwt", "")
            localStorage.setItem("user", "")
            api.logout!()
        }
    },
    extraReducers: (builder) => {
        //Fecth device info
        builder.addCase(login.pending, (state) => {
            state.authenticating = true;
            state.error = null
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.authenticating = false;
            state.jwt = action.payload.jwt;
            state.user = action.payload.user
            localStorage.setItem("jwt", action.payload.jwt)
            localStorage.setItem("user", JSON.stringify(state.user))
            
        });
        builder.addCase(login.rejected, (state, action :any) => {
            state.authenticating = false;
            state.error = i18n.t("invalid-login")
            
        });

        builder.addCase(fetchMe.pending, (state) => {
            
        });
        builder.addCase(fetchMe.fulfilled, (state, action) => {
            state.user = action.payload
            
        });
        builder.addCase(fetchMe.rejected, (state, action :any) => {
            
            
        });
    }
})



const { logout } = authSlice.actions

export {
    logout
}

export default authSlice