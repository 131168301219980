import React, {useState, useEffect} from 'react'
import i18n from '../../locale/i18'
import { api } from '../../utils/api'

const BusinessOrders = () => {

    const [Orders, setOrders] = useState([])

   useEffect(() => {
       
    api.get("/orders")
    .then(({ data }) => {
        setOrders(data)
    })
   }, [])

    return (
        <div className="main-content">
            <div className="container-fluid">
                <div className="header">
                    <div className="header-body">
                        <div className="row align-items-center">
                            <div className="col">


                                <h6 className="header-pretitle">
                                    Overview
                    </h6>


                                <h1 className="header-title text-truncate">
                                    {i18n.t("business-order")}
                                </h1>

                            </div>

                        </div>

                    </div>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{i18n.t("business-money-name")}</th>
                            <th scope="col">{i18n.t("business-customer-order-number")}</th>
                            <th scope="col">{i18n.t("business-customer-order-timestamp")}</th>
                            <th scope="col">{i18n.t("business-customer-name")}</th>
                            <th scope="col">{i18n.t("business-customer-phone")}</th>
                            <th scope="col">{i18n.t("business-customer-address")}</th>
                            <th scope="col">{i18n.t("business-customer-order-full-data")}</th>
                            <th scope="col">{i18n.t("business-customer-order-sum")}</th>
                            <th scope="col">{i18n.t("business-money-comission-9")}</th>
                            <th scope="col">{i18n.t("business-money-comission-5")}</th>
                            <th scope="col">{i18n.t("business-money-comission-2")}</th>
                            <th scope="col">{i18n.t("business-money-comission-2")}</th>
                            
                           
                            <th scope="col">{i18n.t("business-customer-order-delivery-time")}</th>
                            <th scope="col">{i18n.t("business-customer-order-review")}</th>
                            <th scope="col">{i18n.t("business-customer-order-rating")}</th>
                            <th scope="col">{i18n.t("business-customer-member")}</th>
                            
                            
                            <th scope="col">{i18n.t("business-customer-first-order")}</th>
                            <th scope="col">{i18n.t("business-customer-first-order-from-business")}</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {Orders.map((order :any, key) => {
                            return <tr key={key}>
                                <th scope="row">{key}</th>
                                <td>{order.restaurant.name}</td>
                                <td>{order.id}</td>
                                <td>{order.created_at}</td>
                                <td>{order.name}</td>
                                <td>{order.phone}</td>
                                <td>{order.address}</td>
                                <td>{}</td>
                                <td>{order.total}</td>
                                
                               
                                
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BusinessOrders
