import React, {useState, useEffect, useRef} from 'react'
import classnames from 'classnames'
import { MemberType, sendSMS } from '../../../redux/membersclubSlice'
import i18n from '../../../locale/i18'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'

function usePrevious(value:any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

const SendSMSModal = ({ Member, onClose} : {onClose: () => void, Member : MemberType | undefined}) => {
    const dispatch = useDispatch()
    const [Body, setBody] = useState("")
    const [Complete, setComplete] = useState(false)

    const membersclub = useSelector((state:RootState) => state.membersclub)
    const prevSmsSending = usePrevious(membersclub.smsSending)
    const SendSMS = () => {
        if(Member?.id)
            dispatch(sendSMS({
                id : Member?.id,
                body : Body
            }))
    }

    useEffect(() => {
        if(prevSmsSending && !membersclub.smsSending){
            onClose()
            setBody("")
        }
        
    }, [membersclub.smsSending, prevSmsSending])

    useEffect(() => {
        
        
        setComplete(Body ? true : false)
        
    }, [Body])
    
    const onChange = (e:any) => {
        switch(e.target.name){
            case "body":
                setBody(e.target.value)
                break;
            default:
                break;
        }
    }
    
    return <div id="sendSMSModal" className={classnames("modal",  "fade")} tabIndex={-1} role="dialog" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Send SMS</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="card-header">
                      <div className="row no-gutters align-items-center">
                        <div className="col-3">

                          
                          <label className="mb-0" htmlFor="inviteMemberEmail">
                            {i18n.t("sms-body")}
                          </label>

                        </div>
                        <div className="col">

                          
                          <input value={Body} className="form-control form-control-flush" onChange={onChange} type="text" name="body" placeholder="Body..." />

                        </div>
                      </div> 
                    </div> 
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" disabled={!Complete} className={classnames("btn btn-primary", {disabled : !Complete})} onClick={() => SendSMS()}>Send SMS</button>
                </div>
                </div>
            </div>
        </div>
    
}

export default SendSMSModal
