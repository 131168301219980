import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import i18n from '../../locale/i18'
import { getRestaurantDetails } from '../../redux/restaurantSlice'
import { logout } from '../../redux/authSlice'
import { useDispatch } from 'react-redux'

const SideBar = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const currentPath = history.location.pathname





  return (

    <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
      <div className="container-fluid">


        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>


        <a className="navbar-brand" href="./index.html">
          <img src="/images/bolepo3.png" className="navbar-brand-img 
          mx-auto" alt="..." />
        </a>


        <div className="navbar-user d-md-none">


          <div className="dropdown">


            <a href="#" id="sidebarIcon" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div className="avatar avatar-sm avatar-online">
                <img src="/assets/img/avatars/profiles/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
              </div>
            </a>


            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
              <a href="./profile-posts.html" className="dropdown-item">Profile</a>
              <a href="./account-general.html" className="dropdown-item">Settings</a>
              <hr className="dropdown-divider" />
              <a href="#" onClick={() => dispatch(logout())} className="dropdown-item">Logout</a>
            </div>

          </div>

        </div>


        <div className="collapse navbar-collapse" id="sidebarCollapse">


          <form className="mt-4 mb-3 d-md-none">
            <div className="input-group input-group-rounded input-group-merge">
              <input type="search" className="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search" />
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span className="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>


          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => history.replace("/")}>
                <i className="fe fe-home"></i> {i18n.t("businesses")}
              </a>

              {currentPath.indexOf("/restaurant/") >= 0 && [<a key={0} className="nav-link" href="" onClick={() => history.replace(currentPath + "/members")}>
                <i className="fe fe-book"></i> {i18n.t("membersclub")}
              </a>]}


              {/* <div className="collapse " id="sidebarDashboards">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <a href="./index.html" className="nav-link ">
                      Default
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./dashboard-project-management.html" className="nav-link ">
                      Project Management
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./dashboard-ecommerce.html" className="nav-link ">
                      E-Commerce
                    </a>
                  </li>
                </ul>
              </div> */}
            </li>

          </ul>


          <hr className="navbar-divider my-3" />


          <h6 className="navbar-heading">
            {i18n.t("reports")}
          </h6>

          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => history.replace("/business-money")}>
                <i className="fe fe-bar-chart"></i> {i18n.t("business-money-data")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => history.replace("/business-reviews")}>
                <i className="fe fe-bar-chart"></i> {i18n.t("business-review")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => history.replace("/business-customers")}>
                <i className="fe fe-bar-chart"></i> {i18n.t("business-customer")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => history.replace("/business-orders")}>
                <i className="fe fe-bar-chart"></i> {i18n.t("business-order")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => history.replace("/business-members")}>
                <i className="fe fe-bar-chart"></i> {i18n.t("business-member")}
              </a>
            </li>
          </ul>


          <hr className="navbar-divider my-3" />


          <h6 className="navbar-heading">
            Info
          </h6>


          <ul className="navbar-nav mb-md-4">

            <li className="nav-item">
              <a className="nav-link " href="./docs/changelog.html">
                <i className="fe fe-git-branch"></i> Changelog <span className="badge badge-primary ml-auto">v1.0.0</span>
              </a>
            </li>
          </ul>


          <div className="mt-auto"></div>





          <div className="navbar-user d-none d-md-flex" id="sidebarUser">


            <a href="#sidebarModalActivity" className="navbar-user-link" data-toggle="modal">
              <span className="icon">
                <i className="fe fe-bell"></i>
              </span>
            </a>


            <div className="dropup">


              <a href="#" id="sidebarIconCopy" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="avatar avatar-sm avatar-online">
                  <img src="/assets/img/avatars/profiles/avatar-1.jpg" className="avatar-img rounded-circle" alt="..." />
                </div>
              </a>


              <div className="dropdown-menu" aria-labelledby="sidebarIconCopy">
                <a href="./profile-posts.html" className="dropdown-item">Profile</a>
                <a href="./account-general.html" className="dropdown-item">Settings</a>
                <hr className="dropdown-divider" />
                <a href="#" onClick={() => dispatch(logout())} className="dropdown-item">Logout</a>
              </div>

            </div>


            <a href="#sidebarModalSearch" className="navbar-user-link" data-toggle="modal">
              <span className="icon">
                <i className="fe fe-search"></i>
              </span>
            </a>

          </div>


        </div>

      </div>
    </nav>




  )
}

export default SideBar
