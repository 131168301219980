import React from 'react'

const Modals = () => {
    return (
        <div>
            <div className="modal fade" id="modalMembers" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-card card" data-list='{"valueNames": ["name"]}'>
            <div className="card-header">

              
              <h4 className="card-header-title" id="exampleModalCenterTitle">
                Add a member
              </h4>

              
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>

            </div>
            <div className="card-header">

              
              <form>
                <div className="input-group input-group-flush input-group-merge">

                  
                  <input type="search" className="form-control form-control-prepended list-search" placeholder="Search" />

                  
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <span className="fe fe-search"></span>
                    </div>
                  </div>

                </div>
              </form>

            </div>
            <div className="card-body">

              
              <ul className="list-group list-group-flush list my-n3">
                <li className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <a href="./profile-posts.html" className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-5.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="mb-1 name">
                        <a href="./profile-posts.html">Miyah Myles</a>
                      </h4>

                      
                      <p className="small mb-0">
                        <span className="text-success">●</span> Online
                      </p>

                    </div>
                    <div className="col-auto">

                      
                      <a href="#!" className="btn btn-sm btn-white">
                        Add
                      </a>

                    </div>
                  </div> 
                </li>
                <li className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <a href="./profile-posts.html" className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-6.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="mb-1 name">
                        <a href="./profile-posts.html">Ryu Duke</a>
                      </h4>

                      
                      <p className="small mb-0">
                        <span className="text-success">●</span> Online
                      </p>

                    </div>
                    <div className="col-auto">

                      
                      <a href="#!" className="btn btn-sm btn-white">
                        Add
                      </a>

                    </div>
                  </div> 
                </li>
                <li className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <a href="./profile-posts.html" className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-7.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="mb-1 name">
                        <a href="./profile-posts.html">Glen Rouse</a>
                      </h4>

                      
                      <p className="small mb-0">
                        <span className="text-warning">●</span> Busy
                      </p>

                    </div>
                    <div className="col-auto">

                      
                      <a href="#!" className="btn btn-sm btn-white">
                        Add
                      </a>

                    </div>
                  </div> 
                </li>
                <li className="list-group-item">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <a href="./profile-posts.html" className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-8.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="mb-1 name">
                        <a href="./profile-posts.html">Grace Gross</a>
                      </h4>

                      
                      <p className="small mb-0">
                        <span className="text-danger">●</span> Offline
                      </p>

                    </div>
                    <div className="col-auto">

                      
                      <a href="#!" className="btn btn-sm btn-white">
                        Add
                      </a>

                    </div>
                  </div> 
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="modal fade" id="sidebarModalSearch" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-vertical" role="document">
        <div className="modal-content">
          <div className="modal-body" data-list='{"valueNames": ["name"]}'>

            
            <form className="mb-4">
              <div className="input-group input-group-merge input-group-rounded">

                
                <input type="search" className="form-control form-control-prepended list-search" placeholder="Search" />

                
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <span className="fe fe-search"></span>
                  </div>
                </div>

              </div>
            </form>

            
            <div className="my-n3">
              <div className="list-group list-group-flush list-group-focus list">
                <a className="list-group-item" href="./team-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <div className="avatar">
                        <img src="./assets/img/avatars/teams/team-logo-1.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="text-body text-focus mb-1 name">
                        Airbnb
                      </h4>

                      
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time dateTime="2018-05-24">Updated 2hr ago</time>
                      </p>

                    </div>
                  </div> 
                </a>
                <a className="list-group-item" href="./team-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <div className="avatar">
                        <img src="./assets/img/avatars/teams/team-logo-2.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="text-body text-focus mb-1 name">
                        Medium Corporation
                      </h4>

                      
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time dateTime="2018-05-24">Updated 2hr ago</time>
                      </p>

                    </div>
                  </div> 
                </a>
                <a className="list-group-item" href="./project-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <div className="avatar avatar-4by3">
                        <img src="./assets/img/avatars/projects/project-1.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="text-body text-focus mb-1 name">
                        Homepage Redesign
                      </h4>

                      
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time dateTime="2018-05-24">Updated 4hr ago</time>
                      </p>

                    </div>
                  </div> 
                </a>
                <a className="list-group-item" href="./project-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <div className="avatar avatar-4by3">
                        <img src="./assets/img/avatars/projects/project-2.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="text-body text-focus mb-1 name">
                        Travels & Time
                      </h4>

                      
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time dateTime="2018-05-24">Updated 4hr ago</time>
                      </p>

                    </div>
                  </div> 
                </a>
                <a className="list-group-item" href="./project-overview.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <div className="avatar avatar-4by3">
                        <img src="./assets/img/avatars/projects/project-3.jpg" alt="..." className="avatar-img rounded" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="text-body text-focus mb-1 name">
                        Safari Exploration
                      </h4>

                      
                      <p className="small text-muted mb-0">
                        <span className="fe fe-clock"></span> <time dateTime="2018-05-24">Updated 4hr ago</time>
                      </p>

                    </div>
                  </div> 
                </a>
                <a className="list-group-item" href="./profile-posts.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <div className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." className="avatar-img rounded-circle" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="text-body text-focus mb-1 name">
                        Dianna Smiley
                      </h4>

                      
                      <p className="text-body small mb-0">
                        <span className="text-success">●</span> Online
                      </p>

                    </div>
                  </div> 
                </a>
                <a className="list-group-item" href="./profile-posts.html">
                  <div className="row align-items-center">
                    <div className="col-auto">

                      
                      <div className="avatar">
                        <img src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle" />
                      </div>

                    </div>
                    <div className="col ml-n2">

                      
                      <h4 className="text-body text-focus mb-1 name">
                        Ab Hadley
                      </h4>

                      
                      <p className="text-body small mb-0">
                        <span className="text-danger">●</span> Offline
                      </p>

                    </div>
                  </div> 
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div className="modal fade" id="sidebarModalActivity" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-vertical" role="document">
        <div className="modal-content">
          <div className="modal-header">

            
            <h4 className="modal-title">
              Notifications
            </h4>

            
            <ul className="nav nav-tabs nav-tabs-sm modal-header-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#modalActivityAction">Action</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#modalActivityUser">User</a>
              </li>
            </ul>

          </div>
          <div className="modal-body">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="modalActivityAction">

                
                <div className="list-group list-group-flush list-group-activity my-n3">
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-mail"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Launchday 1.4.0 update email sent
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Sent to all 1,851 subscribers over a 24 hour period
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-archive"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          New project "Goodkit" created
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Looks like there might be a new theme soon.
                        </p>

                        
                        <small className="text-muted">
                          2h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-code"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Dashkit 1.5.0 was deployed.
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          A successful to deploy to production was executed.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-git-branch"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          "Update Dependencies" branch was created.
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          This branch was created off of the "master" branch.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-mail"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Launchday 1.4.0 update email sent
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Sent to all 1,851 subscribers over a 24 hour period
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-archive"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          New project "Goodkit" created
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Looks like there might be a new theme soon.
                        </p>

                        
                        <small className="text-muted">
                          2h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-code"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Dashkit 1.5.0 was deployed.
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          A successful to deploy to production was executed.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-git-branch"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          "Update Dependencies" branch was created.
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          This branch was created off of the "master" branch.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-mail"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Launchday 1.4.0 update email sent
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Sent to all 1,851 subscribers over a 24 hour period
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-archive"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          New project "Goodkit" created
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Looks like there might be a new theme soon.
                        </p>

                        
                        <small className="text-muted">
                          2h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-code"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Dashkit 1.5.0 was deployed.
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          A successful to deploy to production was executed.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary">
                            <i className="fe fe-git-branch"></i>
                          </div>
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          "Update Dependencies" branch was created.
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          This branch was created off of the "master" branch.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                </div>

              </div>
              <div className="tab-pane fade" id="modalActivityUser">

                
                <div className="list-group list-group-flush list-group-activity my-n3">
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Dianna Smiley
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files "Launchday Logo" and "New Design".
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Ab Hadley
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Shared the "Why Dashkit?" post with 124 subscribers.
                        </p>

                        
                        <small className="text-muted">
                          1h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-offline">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Adolfo Hess
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Exported sales data from Launchday's subscriber data.
                        </p>

                        
                        <small className="text-muted">
                          3h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Dianna Smiley
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files "Launchday Logo" and "New Design".
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Ab Hadley
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Shared the "Why Dashkit?" post with 124 subscribers.
                        </p>

                        
                        <small className="text-muted">
                          1h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-offline">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Adolfo Hess
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Exported sales data from Launchday's subscriber data.
                        </p>

                        
                        <small className="text-muted">
                          3h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Dianna Smiley
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files "Launchday Logo" and "New Design".
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Ab Hadley
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Shared the "Why Dashkit?" post with 124 subscribers.
                        </p>

                        
                        <small className="text-muted">
                          1h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-offline">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Adolfo Hess
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Exported sales data from Launchday's subscriber data.
                        </p>

                        
                        <small className="text-muted">
                          3h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Dianna Smiley
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files "Launchday Logo" and "New Design".
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-online">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Ab Hadley
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Shared the "Why Dashkit?" post with 124 subscribers.
                        </p>

                        
                        <small className="text-muted">
                          1h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                  <a className="list-group-item text-reset" href="#!">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm avatar-offline">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Adolfo Hess
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Exported sales data from Launchday's subscriber data.
                        </p>

                        
                        <small className="text-muted">
                          3h ago
                        </small>

                      </div>
                    </div> 
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="modal fade" id="modalKanbanTask" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-lighter">
          <div className="modal-body">

            
            <div className="row">
              <div className="col">

                
                <h6 className="text-uppercase text-muted mb-3">
                  <a href="#!" className="text-reset">How to Use Kanban</a>
                </h6>

                
                <h2 className="mb-2">
                  Update Dashkit to include new components!
                </h2>

                
                <p className="text-muted mb-0">
                  This is a description of this task. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum magna nisi, ultrices ut pharetra eget.
                </p>

              </div>
              <div className="col-auto">

                
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    &times;
                  </span>
                </button>

              </div>
            </div> 

            
            <hr className="my-4" />

            
            <div className="mb-4">
              <div className="row">
                <div className="col">

                  
                  <a href="#!" className="btn btn-sm btn-white">
                    😬 1
                  </a>
                  <a href="#!" className="btn btn-sm btn-white">
                    👍 2
                  </a>
                  <a href="#!" className="btn btn-sm btn-white">
                    Add Reaction
                  </a>

                </div>
                <div className="col-auto mr-n3">

                  
                  <div className="avatar-group d-none d-sm-flex">
                    <a href="./profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Ab Hadley">
                      <img src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle"/>
                    </a>
                    <a href="./profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Adolfo Hess">
                      <img src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." className="avatar-img rounded-circle"/>
                    </a>
                    <a href="./profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Daniela Dewitt">
                      <img src="./assets/img/avatars/profiles/avatar-4.jpg" alt="..." className="avatar-img rounded-circle"/>
                    </a>
                    <a href="./profile-posts.html" className="avatar avatar-xs" data-toggle="tooltip" title="Miyah Myles">
                      <img src="./assets/img/avatars/profiles/avatar-5.jpg" alt="..." className="avatar-img rounded-circle"/>
                    </a>
                  </div>

                </div>
                <div className="col-auto">

                  
                  <a href="#!" className="btn btn-sm btn-white">
                    Share
                  </a>

                </div>
              </div> 
            </div>

            
            <div className="card">
              <div className="card-header">

                
                <h4 className="card-header-title">
                  Files
                </h4>

                
                <a href="#!" className="btn btn-sm btn-white">
                  Add files
                </a>

              </div>
              <div className="card-body">
                <div className="list-group list-group-flush my-n3">
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">

                        
                        <a href="./project-overview.html" className="avatar">
                          <img src="./assets/img/files/file-1.jpg" alt="..." className="avatar-img rounded" />
                        </a>

                      </div>
                      <div className="col ml-n2">

                        
                        <h4 className="mb-1">
                          <a href="./project-overview.html">Launchday logo</a>
                        </h4>

                        
                        <p className="card-text small text-muted">
                          1.5mb PNG Dave
                        </p>

                      </div>
                      <div className="col-auto">

                        
                        <div className="dropdown">
                          <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#!" className="dropdown-item">
                              Action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Another action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Something else here
                            </a>
                          </div>
                        </div>

                      </div>
                    </div> 
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">

                        
                        <a href="./project-overview.html" className="avatar">
                          <img src="./assets/img/files/file-1.jpg" alt="..." className="avatar-img rounded" />
                        </a>

                      </div>
                      <div className="col ml-n2">

                        
                        <h4 className="mb-1">
                          <a href="./project-overview.html">Launchday logo</a>
                        </h4>

                        
                        <p className="card-text small text-muted">
                          1.5mb PNG Dave
                        </p>

                      </div>
                      <div className="col-auto">

                        
                        <div className="dropdown">
                          <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#!" className="dropdown-item">
                              Action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Another action
                            </a>
                            <a href="#!" className="dropdown-item">
                              Something else here
                            </a>
                          </div>
                        </div>

                      </div>
                    </div> 
                  </div>
                </div>

              </div>
            </div>

            
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-auto">

                    
                    <div className="avatar avatar-sm">
                      <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                    </div>

                  </div>
                  <div className="col ml-n2">

                    
                    <form className="mt-1">
                      <textarea className="form-control form-control-flush form-control" data-toggle="autosize" rows={1} placeholder="Leave a comment"></textarea>
                    </form>

                  </div>
                  <div className="col-auto align-self-end">

                    
                    <div className="text-muted mb-2">
                      <a href="#!" className="text-reset mr-3">
                        <i className="fe fe-camera"></i>
                      </a>
                      <a href="#!" className="text-reset mr-3">
                        <i className="fe fe-paperclip"></i>
                      </a>
                      <a href="#!" className="text-reset">
                        <i className="fe fe-mic"></i>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
              <div className="card-body">

                
                <div className="comment mb-3">
                  <div className="row">
                    <div className="col-auto">

                      
                      <a className="avatar avatar-sm" href="./profile-posts.html">
                        <img src="./assets/img/avatars/profiles/avatar-2.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      
                      <div className="comment-body">

                        <div className="row">
                          <div className="col">

                            
                            <h5 className="comment-title">
                              Ab Hadley
                            </h5>

                          </div>
                          <div className="col-auto">

                            
                            <time className="comment-time">
                              11:12
                            </time>

                          </div>
                        </div> 

                        
                        <p className="comment-text">
                          Looking good Dianna! I like the image grid on the left, but it feels like a lot to process and doesn't really <em>show</em> me what the product does? I think using a short looping video or something similar demo'ing the product might be better?
                        </p>

                      </div>

                    </div>
                  </div> 
                </div>
                <div className="comment">
                  <div className="row">
                    <div className="col-auto">

                      
                      <a className="avatar avatar-sm" href="./profile-posts.html">
                        <img src="./assets/img/avatars/profiles/avatar-3.jpg" alt="..." className="avatar-img rounded-circle" />
                      </a>

                    </div>
                    <div className="col ml-n2">

                      
                      <div className="comment-body">

                        <div className="row">
                          <div className="col">

                            
                            <h5 className="comment-title">
                              Adolfo Hess
                            </h5>

                          </div>
                          <div className="col-auto">

                            
                            <time className="comment-time">
                              11:12
                            </time>

                          </div>
                        </div> 

                        
                        <p className="comment-text">
                          Any chance you're going to link the grid up to a public gallery of sites built with Launchday?
                        </p>

                      </div>

                    </div>
                  </div> 
                </div>

              </div>
            </div>

            
            <div className="card mb-0">
              <div className="card-header">

                
                <h4 className="card-header-title">
                  Activity
                </h4>

              </div>
              <div className="card-body">
                <div className="list-group list-group-flush list-group-activity my-n3">
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Johnathan Goldstein
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files “Launchday Logo” and “Revisiting the Past”.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </div>
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Johnathan Goldstein
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files “Launchday Logo” and “Revisiting the Past”.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </div>
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-auto">

                        
                        <div className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                        </div>

                      </div>
                      <div className="col ml-n2">

                        
                        <h5 className="mb-1">
                          Johnathan Goldstein
                        </h5>

                        
                        <p className="small text-gray-700 mb-0">
                          Uploaded the files “Launchday Logo” and “Revisiting the Past”.
                        </p>

                        
                        <small className="text-muted">
                          2m ago
                        </small>

                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div className="modal fade" id="modalKanbanTaskEmpty" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content bg-lighter">
          <div className="modal-body">

            
            <div className="row">
              <div className="col">

                
                <h6 className="text-uppercase text-muted mb-3">
                  <a href="#!" className="text-reset">How to Use Kanban</a>
                </h6>

                
                <h2 className="mb-2">
                  Update Dashkit to include new components!
                </h2>

                
                <textarea className="form-control form-control-flush form-control-auto" data-toggle="autosize" rows={1} placeholder="Add a description..."></textarea>

              </div>
              <div className="col-auto">

                
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    &times;
                  </span>
                </button>

              </div>
            </div> 

            
            <hr className="my-4" />

            
            <div className="mb-4">
              <div className="row">
                <div className="col">

                  
                  <a href="#!" className="btn btn-sm btn-white">
                    Add Reaction
                  </a>

                </div>
                <div className="col-auto">

                  
                  <a href="#!" className="btn btn-sm btn-white">
                    Share
                  </a>

                </div>
              </div> 
            </div>

            
            <div className="card">
              <div className="card-body">
                <div className="dropzone dropzone-multiple" data-toggle="dropzone" data-options='{"url": "https://"}'>

                  
                  <div className="fallback">
                    <div className="custom-file">
                      <input type="file" className="custom-file-input" id="customFileUpload" multiple />
                      <label className="custom-file-label" htmlFor="customFileUpload">Choose file</label>
                    </div>
                  </div>

                  
                  <ul className="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
                    <li className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col-auto">

                          
                          <div className="avatar">
                            <img className="avatar-img rounded" src="data:image/svg+xml,%3csvg3c/svg%3e" alt="..." data-dz-thumbnail />
                          </div>

                        </div>
                        <div className="col ml-n3">

                          
                          <h4 className="mb-1" data-dz-name>...</h4>

                          
                          <small className="text-muted" data-dz-size></small>

                        </div>
                        <div className="col-auto">

                          
                          <div className="dropdown">
                            <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fe fe-more-vertical"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a href="#" className="dropdown-item" data-dz-remove>
                                Remove
                              </a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>

            
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto">

                    
                    <div className="avatar avatar-sm">
                      <img className="avatar-img rounded-circle" src="./assets/img/avatars/profiles/avatar-1.jpg" alt="..." />
                    </div>

                  </div>
                  <div className="col ml-n2">

                    
                    <form className="mt-1">
                      <textarea className="form-control form-control-flush" data-toggle="autosize" rows={1} placeholder="Leave a comment"></textarea>
                    </form>

                  </div>
                  <div className="col-auto align-self-end">

                    
                    <div className="text-muted mb-2">
                      <a href="#!" className="text-reset mr-3">
                        <i className="fe fe-camera"></i>
                      </a>
                      <a href="#!" className="text-reset mr-3">
                        <i className="fe fe-paperclip"></i>
                      </a>
                      <a href="#!" className="text-reset">
                        <i className="fe fe-mic"></i>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
        </div>
    )
}

export default Modals
