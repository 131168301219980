import Axios, { AxiosInstance } from 'axios'

declare interface CustomAxiosInstance extends AxiosInstance {
    getMedia?: (media: { url: string | null }) => string,
    serializeJSON? : (data : any) => string,
    logout? : () => void
}
const api:CustomAxiosInstance = Axios.create({
    //baseURL : 'http://localhost:1337'
    baseURL : 'https://bolepo.menual.net'
})
const menual:CustomAxiosInstance = Axios.create({
    baseURL : 'https://menualdev.azurewebsites.net'
})

menual.serializeJSON = (data) => {
  return Object.keys(data)
    .map(function (keyName) {
      return (
        encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName])
      );
    })
    .join("&");
};

// menual.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     if (config.method == "POST") {
//         config.data = menual.serializeJSON(config.data)
//     }

//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

const jwtToken = localStorage.getItem("jwt")            

console.log("jwtToken", jwtToken)
if(jwtToken)
    api.defaults.headers["Authorization"] = "Bearer " + jwtToken

  api.logout = () => {
    delete api.defaults.headers["Authorization"]
  }
//menual.getMedia = 
api.getMedia = (media: { url: string | null }) => {
  if (!media)
    return " "

    const url = api.defaults.baseURL + media.url!
    //console.log("getMedia url = ", url)
    return url;
}

export {
    api,
    menual
}