import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRestaurant } from '../redux/restaurantSlice'
import { RootState } from '../store'

import SideBar from './components/SideBar'
import Modals from './components/Modals'
import i18n from '../locale/i18'
import { api } from '../utils/api'
import { useHistory } from 'react-router-dom'

const Dashboard = () => {
    const dispatch = useDispatch()
    const [Search, setSearch] = useState("")
    const User = useSelector((state: RootState) => state.auth.user)
    const history = useHistory();
    const getRestaurants = () => {
        dispatch(fetchRestaurant())
    }

    const onSearch = (e :any) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        //getRestaurants()
        return () => {
            
        }
    }, [])

    return (
        
    
    
    
    <div className="main-content">

      

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">

            
            <div className="header">
              <div className="header-body">
                <div className="row align-items-center">
                  <div className="col">

                    
                    <h6 className="header-pretitle">
                      Overview
                    </h6>

                    
                    <h1 className="header-title text-truncate">
                      {i18n.t("businesses")}
                    </h1>

                  </div>
                  <div className="col-auto">

                    
                    

                    
                    <a href="#!" className="btn btn-primary ml-2">
                      Add company
                    </a>

                  </div>
                </div> 
                {/* <div className="row align-items-center">
                  <div className="col">

                    
                    <ul className="nav nav-tabs nav-overflow header-tabs">
                      <li className="nav-item">
                        <a href="#!" className="nav-link text-nowrap active">
                          All companies <span className="badge badge-pill badge-soft-secondary">627</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#!" className="nav-link text-nowrap">
                          Your companies <span className="badge badge-pill badge-soft-secondary">198</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#!" className="nav-link text-nowrap">
                          Deleted <span className="badge badge-pill badge-soft-secondary">29</span>
                        </a>
                      </li>
                    </ul>

                  </div>
                </div> */}
              </div>
            </div>

            
            <div className="tab-content">
              <div className="tab-pane fade show active" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">

                
                <div className="card" data-list='{"valueNames": ["item-name", "item-industry", "item-location", "item-owner", "item-created"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}' id="companiesList">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">

                        
                        <form>
                          <div className="input-group input-group-flush">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fe fe-search"></i>
                              </span>
                            </div>
                            <input onChange={onSearch} className="list-search form-control" type="search" placeholder="Search" />
                          </div>
                        </form>

                      </div>
                      <div className="col-auto mr-n3">

                        
                        <form>
                          <select className="custom-select custom-select-sm form-control-flush" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                            <option defaultValue="5" selected>5 per page</option>
                            <option value="10">10 per page</option>
                            <option value="*">All</option>
                          </select>
                        </form>

                      </div>
                      <div className="col-auto">

                        
                        {/* <div className="dropdown">

                          
                          <button className="btn btn-sm btn-white" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-sliders mr-1"></i> Filter <span className="badge badge-primary ml-1">1</span>
                          </button>

                          
                          <form className="dropdown-menu dropdown-menu-right dropdown-menu-card">
                            <div className="card-header">

                              
                              <h4 className="card-header-title">
                                Filters
                              </h4>

                              
                              <button className="btn btn-sm btn-link text-reset" type="reset">
                                <small>Clear filters</small>
                              </button>

                            </div>
                            <div className="card-body">

                              
                              <div className="list-group list-group-flush mt-n4 mb-4">
                                <div className="list-group-item">
                                  <div className="row">
                                    <div className="col">

                                      
                                      <small>Title</small>

                                    </div>
                                    <div className="col-auto">

                                      
                                      <select className="custom-select custom-select-sm" name="item-title" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                                        <option value="*">Any</option>
                                        <option value="Designer" selected>Designer</option>
                                        <option value="Developer">Developer</option>
                                        <option value="Owner">Owner</option>
                                        <option value="Founder">Founder</option>
                                      </select>

                                    </div>
                                  </div> 
                                </div>
                                <div className="list-group-item">
                                  <div className="row">
                                    <div className="col">

                                      
                                      <small>Lead scrore</small>

                                    </div>
                                    <div className="col-auto">

                                      
                                      <select className="custom-select custom-select-sm" name="item-score" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                                        <option value="*" selected>Any</option>
                                        <option value="1/10">1+</option>
                                        <option value="2/10">2+</option>
                                        <option value="3/10">3+</option>
                                        <option value="4/10">4+</option>
                                        <option value="5/10">5+</option>
                                        <option value="6/10">6+</option>
                                        <option value="7/10">7+</option>
                                        <option value="8/10">8+</option>
                                        <option value="9/10">9+</option>
                                        <option value="10/10">10</option>
                                      </select>

                                    </div>
                                  </div> 
                                </div>
                              </div>

                              
                              <button className="btn btn-block btn-primary" type="submit">
                                Apply filter
                              </button>

                            </div>
                          </form>

                        </div> */}

                      </div>
                    </div> 
                  </div>
                  <div className="table-responsive">
                    <table className="table table-sm table-hover table-nowrap card-table">
                      <thead>
                        <tr>
                          <th>

                            
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="list-checkbox-all custom-control-input" id="listCheckboxAll" />
                              <label className="custom-control-label" htmlFor="listCheckboxAll"></label>
                            </div>

                          </th>
                          <th>
                            <a className="list-sort text-muted" data-sort="item-name" href="#">Name</a>
                          </th>
                          <th>
                            <a className="list-sort text-muted" data-sort="item-industry" href="#">Industry</a>
                          </th>
                          <th>
                            <a className="list-sort text-muted" data-sort="item-location" href="#">Location</a>
                          </th>
                          <th>
                            <a className="list-sort text-muted" data-sort="item-owner" href="#">Owner</a>
                          </th>
                          <th colSpan={2}>
                            <a className="list-sort text-muted" data-sort="item-created" href="#">Created at</a>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list font-size-base">
                        {User!.restaurants.map( (restaurant, key) => {
                        if(restaurant.name.toLowerCase().indexOf(Search.toLowerCase()) == -1){
                            return null
                        }
                        return <tr key={key}>
                          <td>

                            
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="list-checkbox custom-control-input" id="listCheckboxOne" />
                              <label className="custom-control-label" htmlFor="listCheckboxOne"></label>
                            </div>

                          </td>
                          <td>

                            
                            <div className="avatar avatar-xs align-middle mr-2">
                              <img className="avatar-img rounded-circle" src={restaurant.logo && api.getMedia ? api.getMedia(restaurant.logo!) : "assets/img/avatars/teams/team-logo-1.jpg"} alt="..." />
                            </div> <a className="item-name text-reset" href="" onClick={() => history.push("/restaurant/" + restaurant.id)}>{restaurant.name}</a>

                          </td>
                          <td>

                            
                            {restaurant.tags && <span className="item-industry">{restaurant.tags.map( tag => tag.value).join(",")}</span>}

                          </td>
                          <td>

                            
                            <span className="item-location">{restaurant.location?.city} {restaurant.location ? " ," + restaurant.location.state : ""}</span>

                          </td>
                          <td className="item-phone">

                            
                            <div className="avatar avatar-xs align-middle mr-2">
                              {/* {<img className="avatar-img rounded-circle" src="assets/img/avatars/profiles/avatar-1.jpg" alt="..." />} */}
                            </div> 
                            {restaurant.restaurant_types && <a className="item-owner text-reset" href="profile-posts.html">{restaurant.restaurant_types.map(type => type.name).join(",")}</a>}

                          </td>
                          <td>

                            
                            <time className="item-created" dateTime="2020-01-14">Jan 14, 2020</time>

                          </td>
                          <td className="text-right">

                            
                            <div className="dropdown">
                              <a className="dropdown-ellipses dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe fe-more-vertical"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a href="#!" className="dropdown-item">
                                  Action
                                </a>
                                <a href="#!" className="dropdown-item">
                                  Another action
                                </a>
                                <a href="#!" className="dropdown-item">
                                  Something else here
                                </a>
                              </div>
                            </div>

                          </td>
                        </tr>})}
                        
                        
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer d-flex justify-content-between">

                    
                    <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                      <li className="page-item">
                        <a className="page-link pl-0 pr-4 border-right" href="#">
                          <i className="fe fe-arrow-left mr-1"></i> Prev
                        </a>
                      </li>
                    </ul>

                    
                    <ul className="list-pagination pagination pagination-tabs card-pagination"></ul>

                    
                    <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                      <li className="page-item">
                        <a className="page-link pl-4 pr-0 border-left" href="#">
                          Next <i className="fe fe-arrow-right ml-1"></i>
                        </a>
                      </li>
                    </ul>

                    
                    <div className="list-alert alert alert-dark alert-dismissible border fade" role="alert">

                      
                      <div className="row align-items-center">
                        <div className="col">

                          
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="cardAlertCheckbox" checked disabled />
                            <label className="custom-control-label text-white" htmlFor="cardAlertCheckbox"><span className="list-alert-count">0</span> deal(s)</label>
                          </div>

                        </div>
                        <div className="col-auto mr-n3">

                          
                          <button className="btn btn-sm btn-white-20">
                            Edit
                          </button>

                          
                          <button className="btn btn-sm btn-white-20">
                            Delete
                          </button>

                        </div>
                      </div> 

                      
                      <button type="button" className="list-alert-close close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>

                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

    </div> 
    )
}

export default Dashboard
