import React, { useState, useEffect } from 'react'
import { DefaultRootState, useSelector, useDispatch } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
} from "react-router-dom";

import Login from './Login'
import Dashboard from './Dashboard'
import type {RootState} from '../store'
import { fetchMe } from '../redux/authSlice';

import Modals from './components/Modals'
import SideBar from './components/SideBar'
import Restaurant from './Restaurant'
import Members from './Members'
import BusinessMoney from './BusinessMoney'
import BusinessReviews from './BusinessReviews';
import BusinessCustomers from './BusinessCustomers';
import BusinessOrders from './BusinessOrders';
import BusinessMembers from './BusinessMembers';

export const MainView = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const auth = useSelector((state: RootState) => state.auth)
    const userId = auth.user ? auth.user.id : null
    const getMe = () => {
        dispatch(fetchMe({id : auth.user!.id}))
    }

    useEffect(() => {
        
        if(userId)
            getMe()
        return () => {
            
        }
    }, [userId])

    // useEffect(() => {
    //     if(auth.jwt)
    //         history.replace("/")
    //     return () => {
            
    //     }
    // }, [auth.jwt])
    
    console.log("auth.user", auth.user)
    return (
        <div>
            <Router>
                {!auth.jwt ? <Redirect to="/login" /> : null}
                <Route path="/login" component={Login} />
                {auth.jwt && <div>
                    <Modals />
                    <SideBar />
                     <Route exact path="/" component={Dashboard} />
                    <Route exact path="/restaurant/:id" component={Restaurant} />
                    
                    <Route path="/restaurant/:id/members" component={Members} />
                    <Route exact path="/business-money" component={BusinessMoney} />
                    <Route exact path="/business-reviews" component={BusinessReviews} />
                    <Route exact path="/business-customers" component={BusinessCustomers} />
                    <Route exact path="/business-orders" component={BusinessOrders} />
                    <Route exact path="/business-members" component={BusinessMembers} />
                    
                    </div>}
                
            </Router>
        </div>
    )
}

export default MainView