// @ts-nocheck
import React, {useEffect, useState} from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import i18n from '../../locale/i18'
import { fetchMembers, MemberType } from '../../redux/membersclubSlice'
import { RootState } from '../../store'
import { api } from '../../utils/api'
import { getRestaurantDetails } from '../../redux/restaurantSlice'
import numeral from 'numeral'
import InviteMemberModal from './containers/InviteMemberModal'
import SendSMSModal from './containers/SendSMSModal'
import { Modal, ModalProps } from 'react-bootstrap'


const Members = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const {id} = useParams<{id? :string | undefined}>()
    const [Member, setMember] = useState<MemberType | undefined>()

    const restaurant = useSelector((state:RootState) => state.restaurant)
    const membersclub = useSelector((state:RootState) => state.membersclub)
    const {restaurantDetails} = restaurant

    const getDetails = () => {
      console.log("getDetails")
        dispatch(getRestaurantDetails({id}))
    }

    useEffect(() => {
        getDetails()
        return () => {
            
        }
    }, [])

    const getMembers = () => {
        dispatch(fetchMembers({id : id!}))
    }

    useEffect(() => {
      
        getMembers()
        return () => {
            
        }
    }, [])
    const Membersclub = useSelector((state : RootState) => state.membersclub)

    const onClose = () => {

      setMember(undefined)
      let smsModal: ModalProps = $('#sendSMSModal')
      smsModal.modal('hide')
    }
    

    return (
        <div className="main-content">

      

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8">

            
            <div className="header mt-md-5">
              <div className="header-body">
                <div className="row align-items-center">
                  <div className="col">

                    
                    <h6 className="header-pretitle">
                      {restaurantDetails?.name}
                    </h6>

                    
                    <h1 className="header-title">
                      Account
                    </h1>

                  </div>
                </div> 
                
              </div>
            </div>

             

            
            <div className="card">
              <div className="card-header">

                
                <h4 className="card-header-title">
                  Members
                </h4>

                
                <InviteMemberModal />

              </div>
              <div className="card-body">

                
                <div className="list-group list-group-flush my-n3">
                  {Membersclub.members.map((member :MemberType, key : number) => <div key={key} className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">

                        
                        <a href="profile-posts.html" className="avatar">
                          <img src={member.users_permissions_user.avatar ? api.getMedia!(member.users_permissions_user.avatar.formats.thumbnail) : "/assets/img/avatars/profiles/avatar-1.jpg"} alt="..." className="avatar-img rounded-circle" />
                        </a>

                      </div>
                      <div className="col-5 ml-n2">

                        
                        <h4 className="mb-1">
                          <a href="profile-posts.html">{member.users_permissions_user.username}</a>
                        </h4>

                        
                        <p className="small text-muted mb-0">
                          <a className="d-block text-reset text-truncate" href={`mailto:${member.users_permissions_user.email}`}>{member.users_permissions_user.email}</a>
                        </p>

                      </div>
                      <div className="col-auto ml-auto mr-n3" style={{
                          display : 'flex',
                          flexDirection : 'row',
                          justifyContent : 'center',
                          alignItems : 'center'
                      }}>

                        <img src="/images/dollar.png" height="20" style={{
                            marginLeft : 5,
                            marginRight : 5
                        }}/>
                        <span>{numeral(member.balance).format('0,0.00')}</span>
                        {/* <select className="custom-select custom-select-sm" data-toggle="select" data-options='{"minimumResultsForSearch": -1}'>
                          <option>Admin</option>
                          <option>Staff</option>
                          <option>Custom</option>
                        </select> */}

                      </div>
                      <div className="col-auto">

                        
                        <div className="dropdown">
                          <a className="dropdown-ellipses dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fe fe-more-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="#" onClick={() => {
                              setMember(member)
                              let smsModal: ModalProps = $('#sendSMSModal')
                              smsModal.modal()
                              }}>
                              Send SMS
                            </a>
                            {/* <a className="dropdown-item" href="#!">
                              Send Notification
                            </a> */}
                            {/* <a className="dropdown-item" href="#!">
                              Something else here
                            </a> */}
                          </div>
                        </div>

                      </div>
                    </div> 
                  </div>)}
                  
                </div>

              </div>
            </div>

            <br />

          </div>
        </div> 
      </div>
      <SendSMSModal {...{onClose, Member}}/>
    </div> 
    )
}

export default Members
