import React, {useState, useEffect} from 'react'
import i18n from '../../locale/i18'
import { api } from '../../utils/api'

const BusinessOrders = () => {

    const [MembersClub, setMembersClub] = useState([])

   useEffect(() => {
       
    api.get("/membersclubs")
    .then(({ data }) => {
        console.log("membresclub", data)
        setMembersClub(data)
    })
   }, [])

    return (
        <div className="main-content">
            <div className="container-fluid">
                <div className="header">
                    <div className="header-body">
                        <div className="row align-items-center">
                            <div className="col">


                                <h6 className="header-pretitle">
                                    Overview
                    </h6>


                                <h1 className="header-title text-truncate">
                                    {i18n.t("business-member")}
                                </h1>

                            </div>

                        </div>

                    </div>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{i18n.t("members-name")}</th>
                            <th scope="col">{i18n.t("members-address")}</th>
                            <th scope="col">{i18n.t("members-phone")}</th>
                            <th scope="col">{i18n.t("members-timestamp")}</th>
                            <th scope="col">{i18n.t("members-email")}</th>
                            <th scope="col">{i18n.t("members-dob")}</th>
                            <th scope="col">{i18n.t("members-anniversary")}</th>
                            <th scope="col">{i18n.t("members-gender")}</th>
                            <th scope="col">{i18n.t("members-coins-given")}</th>
                            <th scope="col">{i18n.t("members-coins-received")}</th>
                            <th scope="col">{i18n.t("members-sum")}</th>
                            <th scope="col">{i18n.t("members-last-order")}</th>
                            <th scope="col">{i18n.t("members-approved-commercial")}</th>
                            <th scope="col">{i18n.t("members-receive-sms")}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {MembersClub.map((restaurant :any, key) => {
                            let user = restaurant.users_permissions_user
                            return <tr key={key}>
                                <th scope="row">{key}</th>
                                <td>{user.firstName + " " + user.lastName}</td>
                                <td>{user.address}</td>
                                <td>{restaurant.telephone}</td>
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BusinessOrders
