import { createSlice, } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '../locale/i18';
import { api } from '../utils/api';

export type LoginParamsType = {
    identifier: string;
    password: string;
}
export const fetchRestaurant = createAsyncThunk(
    'restaurant/fetch',
    async () => {
        console.log("restaurantSlice fetchRestaurant baseURL", api.defaults.baseURL + '/restaurants');

        const response = await api.get(
            '/restaurants'
        );
        console.log('fetchRestaurant response', response.data);
        return response.data;

    }
);
export const getRestaurantDetails = createAsyncThunk(
    'restaurant/details',
    async (params : any) => {
        console.log("restaurantSlice getRestaurantDetails baseURL", api.defaults.baseURL + '/restaurants/' + params.id);

        const response = await api.get(
            '/restaurants/' + params.id
        );
        console.log('getRestaurantDetails response', response.data);
        return response.data;

    }
);

export type RestaurantType = {
    LocationID: "ce85e10c-7486-40bf-b5b3-63b960142ee9"
    address: ""
    averagePrice: null
    averageTime: null
    color: "#8b0000"
    colorText: "#FFFFFF"
    created_at: "2020-12-22T06:04:05.923Z"
    currency: null
    description: null
    facebook: null
    featured: []
    id: 38
    instagram: null
    kosher: null
    lang: null
    location: {
        city: "New York"
        created_at: "2020-12-14T01:52:35.128Z"
        id: 1
        latitude: -73.95498
        longitude: 40.799922
        published_at: "2020-12-14T01:52:40.336Z"
        state: "NY"
        updated_at: "2020-12-14T02:06:39.645Z"
    }
    logo: null
    name: "Picante"
    photos: []
    published_at: "2020-12-22T06:04:05.441Z"
    rate: null
    restaurant_types: [
        { id: 2, name: "Mexican", published_at: "2021-01-01T20:31:45.563Z", created_at: "2021-01-01T20:31:44.396Z", updated_at: "2021-01-01T20:31:45.602Z" }

    ]
    tags: [
        {
            created_at: "2021-01-05T01:03:37.194Z"
            id: 1
            published_at: "2021-01-05T01:03:42.206Z"
            restaurant: null
            updated_at: "2021-01-05T01:03:42.242Z"
            value: "Taco"
        },
        {
            created_at: "2021-01-05T01:04:33.483Z"
            id: 4
            published_at: "2021-01-05T01:04:35.139Z"
            restaurant: 35
            updated_at: "2021-01-05T01:06:01.991Z"
            value: "Spicy"
        },
        {
            created_at: "2021-01-05T01:03:52.640Z"
            id: 2
            published_at: "2021-01-05T01:03:53.807Z"
            restaurant: 17
            updated_at: "2021-01-05T05:07:13.750Z"
            value: "Mexican"
        }

    ]
    tax: null
    telephone: ""
    updated_at: "2021-01-07T07:45:05.546Z"
}

type InitialStateType = {
    fetching: boolean;
    error: any,
    restaurants: Array<RestaurantType>,
    restaurantDetails : RestaurantType | null
}



const initialState: InitialStateType = {
    fetching: false,
    error: undefined,
    restaurants: [],
    restaurantDetails : null
}

const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        //Fecth device info
        builder.addCase(fetchRestaurant.pending, (state) => {
            state.fetching = true;
            state.error = null
        });
        builder.addCase(fetchRestaurant.fulfilled, (state, action) => {
            state.fetching = false;
            state.restaurants = action.payload

        });
        builder.addCase(fetchRestaurant.rejected, (state, action: any) => {
            state.fetching = false;
            state.error = action.error.message

        });
        builder.addCase(getRestaurantDetails.pending, (state) => {
            state.fetching = true;
            state.error = null
            state.restaurantDetails = null
        });
        builder.addCase(getRestaurantDetails.fulfilled, (state, action) => {
            state.fetching = false;
            state.restaurantDetails = action.payload

        });
        builder.addCase(getRestaurantDetails.rejected, (state, action: any) => {
            state.fetching = false;
            state.error = action.error.message

        });
    }
})




export default restaurantSlice