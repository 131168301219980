import React, {useState, useEffect} from 'react'
import {Button} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/authSlice';
import { RootState } from '../store';
import { Color } from '../utils/Colors';
import {
  BrowserRouter as Router,
  useHistory,
  Redirect
} from "react-router-dom";
import i18n from '../locale/i18';

export const Login = () => {
    const history = useHistory();
    const auth = useSelector((state : RootState) => state.auth)
    const dispatch = useDispatch()
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    //const state = useSelector(state => state.state)

    useEffect(() => {
      
      if(auth.jwt){
        
      }
      return () => {
        
      }
    }, [auth.jwt])

    const onChange = (e:any) => {
        console.log("onChange", e.target.name)
        switch (e.target.name) {
            case "email":
                setEmail(e.target.value)
                break;
            case "password":
                setPassword(e.target.value)
                break;
        
            default:
                break;
        }
    }

    const doLogin = (e :any) => {
        e.preventDefault()
        dispatch(login({
            identifier : Email,
            password : Password
        }))
    }

    if(auth.jwt){
      return <Redirect to={"/"} />
    }


    return (
        <div style={{
            display : 'flex',
            flexDirection : 'column',
            flex : 1,
            height : "100vh",
            justifyContent : 'center',
            width : "100%"
        }} className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">

          
          <div className="text-center">
          <img src="/images/bolepo3.png" />
          </div>

        </div>
        <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
          
          
          
          <h1 className="display-4 text-center mb-3">
            {i18n.t("sign-in")}
          </h1>
          
          
          <p className="text-muted text-center mb-5">
            
            {i18n.t("sign-in-dashboard")}
          </p>
          
          
          <div>

            
            <div className="form-group">

              
              <label>{i18n.t("sign-in-email")}</label>

              
              <input onChange={onChange} name="email" type="email" className="form-control" placeholder="name@address.com" />

            </div>

            
            <div className="form-group">

              <div className="row">
                <div className="col">
                      
                  
                  <label>{i18n.t("sign-in-password")}</label>

                </div>
                <div className="col-auto">
                  
                  
                  <a href="password-reset-illustration.html" className="form-text small text-muted">
                    {i18n.t("sign-in-forgot-password")}
                  </a>

                </div>
              </div>

              
              <div className="input-group input-group-merge">

                
                <input onChange={onChange} type="password" name="password" className="form-control form-control-appended" placeholder="Enter your password" />

                
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fe fe-eye"></i>
                  </span>
                </div>

              </div>
            </div>

            
            <button className="btn btn-lg btn-block btn-primary mb-3" style={{
                backgroundColor : Color.pink,
                borderColor : Color.pink,
            }} onClick={doLogin}>
              {i18n.t("sign-in-btn")}
            </button>

           
            <div className="text-center">
              <small className="text-muted text-center">
               {i18n.t("sign-in-dont-have")}<a href="sign-up-illustration.html">{i18n.t("sign-in-btn")}</a>.
              </small>
            </div>
            
          </div>
          {auth.error && <div className="alert alert-danger" role="alert">
            {JSON.stringify(auth.error)}
            </div>}
        </div>
      </div>
    </div>
    )
}

export default Login;